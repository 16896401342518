<template>
    <div></div>
</template>

<script>
  import {Toast} from "vant";
  import {doBlind} from "@/api/user";

  export default {
    name: "blind",
    created() {
      const shopid = this.$route.query.shopId;
      const sign = this.$route.query.sign;
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中'
      });
      const code = window.localStorage.getItem('code');
      doBlind({
        shopId: shopid,
        sign: sign,
        code,
      }).then(data => {
        if (data.success == true) {
          this.$store.commit('SET_TOKEN', data.data.token);
          this.$store.commit('SET_INFO', data.data);
          this.$store.commit('SET_TYPE', 'shop');
          toast.clear();
          window.location.href = '/sjhexiao';
        } else {
          toast.clear();
          Toast(data.msg)
        }
        window.localStorage.removeItem('code');
      }).catch(() => {
        window.localStorage.removeItem('code');
      });
    }
  }
</script>
